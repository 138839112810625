export const jobLevelList = (lang: string) => {
	if (lang === 'vi') {
		return [
			{ id: '8', text: 'Thực tập sinh/ Sinh viên' },
			{ id: '1', text: 'Mới tốt nghiệp' },
			{ id: '5', text: 'Nhân viên' },
			{ id: '7', text: 'Trưởng phòng' },
			{ id: '3', text: 'Giám đốc và cấp cao hơn' }
		]
	} else {
		return [
			{ id: '8', text: 'Intern/Student' },
			{ id: '1', text: 'Fresher/Entry level' },
			{ id: '5', text: 'Experienced (non-manager)' },
			{ id: '7', text: 'Manager' },
			{ id: '3', text: 'Director and above' }
		]
	}
}

export const jobLocationList = (lang: string) => {
	if (lang === 'vi') {
		return [
			{
				id: 70,
				text: 'Quốc tế'
			},
			{
				id: 24,
				text: 'Hà Nội'
			},
			{
				id: 29,
				text: 'Hồ Chí Minh'
			},
			{
				id: 28,
				text: 'Hải Phòng'
			},
			{
				id: 17,
				text: 'Đà Nẵng'
			},
			{
				id: 15,
				text: 'Cần Thơ'
			},
			{
				id: 3,
				text: 'Bà Rịa - Vũng Tàu'
			},
			{
				id: 2,
				text: 'An Giang'
			},
			{
				id: 4,
				text: 'Bắc Kạn'
			},
			{
				id: 5,
				text: 'Bắc Giang'
			},
			{
				id: 6,
				text: 'Bạc Liêu'
			},
			{
				id: 7,
				text: 'Bắc Ninh'
			},
			{
				id: 8,
				text: 'Bến Tre'
			},
			{
				id: 10,
				text: 'Bình Định'
			},
			{
				id: 11,
				text: 'Bình Dương'
			},
			{
				id: 12,
				text: 'Bình Phước'
			},
			{
				id: 13,
				text: 'Bình Thuận'
			},
			{
				id: 14,
				text: 'Cà Mau'
			},
			{
				id: 16,
				text: 'Cao Bằng'
			},
			{
				id: 73,
				text: 'Đắk Nông'
			},
			{
				id: 18,
				text: 'Đắk Lắk'
			},
			{
				id: 71,
				text: 'ĐBSCL'
			},
			{
				id: 69,
				text: 'Điện Biên'
			},
			{
				id: 19,
				text: 'Đồng Nai'
			},
			{
				id: 20,
				text: 'Đồng Tháp'
			},
			{
				id: 21,
				text: 'Gia Lai'
			},
			{
				id: 22,
				text: 'Hà Giang'
			},
			{
				id: 23,
				text: 'Hà Nam'
			},
			{
				id: 26,
				text: 'Hà Tĩnh'
			},
			{
				id: 27,
				text: 'Hải Dương'
			},
			{
				id: 72,
				text: 'Hậu Giang'
			},
			{
				id: 30,
				text: 'Hòa Bình'
			},
			{
				id: 32,
				text: 'Hưng Yên'
			},
			{
				id: 33,
				text: 'Khánh Hòa'
			},
			{
				id: 34,
				text: 'Kon Tum'
			},
			{
				id: 35,
				text: 'Lai Châu'
			},
			{
				id: 36,
				text: 'Lâm Đồng'
			},
			{
				id: 37,
				text: 'Lạng Sơn'
			},
			{
				id: 38,
				text: 'Lào Cai'
			},
			{
				id: 39,
				text: 'Long An'
			},
			{
				id: 40,
				text: 'Nam Định'
			},
			{
				id: 41,
				text: 'Nghệ An'
			},
			{
				id: 42,
				text: 'Ninh Bình'
			},
			{
				id: 43,
				text: 'Ninh Thuận'
			},
			{
				id: 44,
				text: 'Phú Thọ'
			},
			{
				id: 45,
				text: 'Phú Yên'
			},
			{
				id: 46,
				text: 'Quảng Bình'
			},
			{
				id: 47,
				text: 'Quảng Nam'
			},
			{
				id: 48,
				text: 'Quảng Ngãi'
			},
			{
				id: 49,
				text: 'Quảng Ninh'
			},
			{
				id: 50,
				text: 'Quảng Trị'
			},
			{
				id: 51,
				text: 'Sóc Trăng'
			},
			{
				id: 52,
				text: 'Sơn La'
			},
			{
				id: 53,
				text: 'Tây Ninh'
			},
			{
				id: 54,
				text: 'Thái Bình'
			},
			{
				id: 55,
				text: 'Thái Nguyên'
			},
			{
				id: 56,
				text: 'Thanh Hóa'
			},
			{
				id: 57,
				text: 'Thừa Thiên Huế'
			},
			{
				id: 58,
				text: 'Tiền Giang'
			},
			{
				id: 59,
				text: 'Trà Vinh'
			},
			{
				id: 60,
				text: 'Tuyên Quang'
			},
			{
				id: 61,
				text: 'Kiên Giang'
			},
			{
				id: 62,
				text: 'Vĩnh Long'
			},
			{
				id: 63,
				text: 'Vĩnh Phúc'
			},
			{
				id: 65,
				text: 'Yên Bái'
			},
			{
				id: 66,
				text: 'Khác'
			}
		]
	} else {
		return [
			{
				id: 70,
				text: 'International'
			},
			{
				id: 24,
				text: 'Ha Noi'
			},
			{
				id: 29,
				text: 'Ho Chi Minh'
			},
			{
				id: 28,
				text: 'Hai Phong'
			},
			{
				id: 17,
				text: 'Da Nang'
			},
			{
				id: 15,
				text: 'Can Tho'
			},
			{
				id: 3,
				text: 'Ba Ria - Vung Tau'
			},
			{
				id: 2,
				text: 'An Giang'
			},
			{
				id: 4,
				text: 'Bac Kan'
			},
			{
				id: 5,
				text: 'Bac Giang'
			},
			{
				id: 6,
				text: 'Bac Lieu'
			},
			{
				id: 7,
				text: 'Bac Ninh'
			},
			{
				id: 8,
				text: 'Ben Tre'
			},
			{
				id: 10,
				text: 'Binh Dinh'
			},
			{
				id: 11,
				text: 'Binh Duong'
			},
			{
				id: 12,
				text: 'Binh Phuoc'
			},
			{
				id: 13,
				text: 'Binh Thuan'
			},
			{
				id: 14,
				text: 'Ca Mau'
			},
			{
				id: 16,
				text: 'Cao Bang'
			},
			{
				id: 73,
				text: 'Dak Nong'
			},
			{
				id: 18,
				text: 'Dak Lak'
			},
			{
				id: 71,
				text: 'Mekong Delta'
			},
			{
				id: 69,
				text: 'Dien Bien'
			},
			{
				id: 19,
				text: 'Dong Nai'
			},
			{
				id: 20,
				text: 'Dong Thap'
			},
			{
				id: 21,
				text: 'Gia Lai'
			},
			{
				id: 22,
				text: 'Ha Giang'
			},
			{
				id: 23,
				text: 'Ha Nam'
			},
			{
				id: 26,
				text: 'Ha Tinh'
			},
			{
				id: 27,
				text: 'Hai Duong'
			},
			{
				id: 72,
				text: 'Hau Giang'
			},
			{
				id: 30,
				text: 'Hoa Binh'
			},
			{
				id: 32,
				text: 'Hung Yen'
			},
			{
				id: 33,
				text: 'Khanh Hoa'
			},
			{
				id: 34,
				text: 'Kon Tum'
			},
			{
				id: 35,
				text: 'Lai Chau'
			},
			{
				id: 36,
				text: 'Lam Dong'
			},
			{
				id: 37,
				text: 'Lang Son'
			},
			{
				id: 38,
				text: 'Lao Cai'
			},
			{
				id: 39,
				text: 'Long An'
			},
			{
				id: 40,
				text: 'Nam Dinh'
			},
			{
				id: 41,
				text: 'Nghe An'
			},
			{
				id: 42,
				text: 'Ninh Binh'
			},
			{
				id: 43,
				text: 'Ninh Thuan'
			},
			{
				id: 44,
				text: 'Phu Tho'
			},
			{
				id: 45,
				text: 'Phu Yen'
			},
			{
				id: 46,
				text: 'Quang Binh'
			},
			{
				id: 47,
				text: 'Quang Nam'
			},
			{
				id: 48,
				text: 'Quang Ngai'
			},
			{
				id: 49,
				text: 'Quang Ninh'
			},
			{
				id: 50,
				text: 'Quang Tri'
			},
			{
				id: 51,
				text: 'Soc Trang'
			},
			{
				id: 52,
				text: 'Son La'
			},
			{
				id: 53,
				text: 'Tay Ninh'
			},
			{
				id: 54,
				text: 'Thai Binh'
			},
			{
				id: 55,
				text: 'Thai Nguyen'
			},
			{
				id: 56,
				text: 'Thanh Hoa'
			},
			{
				id: 57,
				text: 'Thua Thien Hue'
			},
			{
				id: 58,
				text: 'Tien Giang'
			},
			{
				id: 59,
				text: 'Tra Vinh'
			},
			{
				id: 60,
				text: 'Tuyen Quang'
			},
			{
				id: 61,
				text: 'Kien Giang'
			},
			{
				id: 62,
				text: 'Vinh Long'
			},
			{
				id: 63,
				text: 'Vinh Phuc'
			},
			{
				id: 65,
				text: 'Yen Bai'
			},
			{
				id: 66,
				text: 'Other'
			}
		]
	}
}

export const jobCategoryList = (lang: string) => {
	if (lang === 'vi') {
		return [
			{
				id: '83',
				text: 'An toàn lao động'
			},
			{
				id: '78',
				text: 'Bác sĩ'
			},
			{
				id: '33',
				text: 'Bán hàng'
			},
			{
				id: '34',
				text: 'Bán hàng kỹ thuật'
			},
			{
				id: '32',
				text: 'Bán lẻ/Bán sỉ'
			},
			{
				id: '24',
				text: 'Bảo hiểm'
			},
			{
				id: '84',
				text: 'Bảo trì/Sửa chữa'
			},
			{
				id: '30',
				text: 'Bất động sản'
			},
			{
				id: '47',
				text: 'Biên phiên dịch'
			},
			{
				id: '17',
				text: 'Cấp quản lý điều hành'
			},
			{
				id: '56',
				text: 'Chứng khoán'
			},
			{
				id: '65',
				text: 'Cơ khí'
			},
			{
				id: '66',
				text: 'Công nghệ cao'
			},
			{
				id: '28',
				text: 'Dầu khí'
			},
			{
				id: '52',
				text: 'Dệt may/Da giày'
			},
			{
				id: '11',
				text: 'Dịch vụ khách hàng'
			},
			{
				id: '6',
				text: 'Dược Phẩm/Công nghệ sinh học'
			},
			{
				id: '80',
				text: 'Dược sĩ'
			},
			{
				id: '12',
				text: 'Giáo dục/Đào tạo'
			},
			{
				id: '62',
				text: 'Hàng cao cấp'
			},
			{
				id: '75',
				text: 'Hàng gia dụng'
			},
			{
				id: '82',
				text: 'Hàng hải'
			},
			{
				id: '37',
				text: 'Hàng không/Du lịch'
			},
			{
				id: '74',
				text: 'Hàng tiêu dùng'
			},
			{
				id: '2',
				text: 'Hành chánh/Thư ký'
			},
			{
				id: '43',
				text: 'Hóa học/Hóa sinh'
			},
			{
				id: '69',
				text: 'Hoạch định/Dự án'
			},
			{
				id: '72',
				text: 'In ấn/ Xuất bản'
			},
			{
				id: '57',
				text: 'Internet/Online Media'
			},
			{
				id: '35',
				text: 'IT - Phần mềm'
			},
			{
				id: '55',
				text: 'IT-Phần cứng/Mạng'
			},
			{
				id: '1',
				text: 'Kế toán'
			},
			{
				id: '39',
				text: 'Khác'
			},
			{
				id: '53',
				text: 'Kho vận'
			},
			{
				id: '58',
				text: 'Kiểm toán'
			},
			{
				id: '5',
				text: 'Kiến trúc/Thiết kế nội thất '
			},
			{
				id: '27',
				text: 'Marketing'
			},
			{
				id: '15',
				text: 'Mới tốt nghiệp'
			},
			{
				id: '16',
				text: 'Môi trường/Xử lý chất thải'
			},
			{
				id: '10',
				text: 'Mỹ Thuật/Nghệ Thuật/Thiết Kế'
			},
			{
				id: '42',
				text: 'Ngân hàng'
			},
			{
				id: '18',
				text: 'Người nước ngoài/Việt Kiều'
			},
			{
				id: '73',
				text: 'Nhà hàng/Khách sạn'
			},
			{
				id: '23',
				text: 'Nhân sự'
			},
			{
				id: '4',
				text: 'Nông nghiệp/Lâm nghiệp'
			},
			{
				id: '71',
				text: 'Overseas Jobs'
			},
			{
				id: '25',
				text: 'Pháp lý'
			},
			{
				id: '21',
				text: 'Phi chính phủ/Phi lợi nhuận'
			},
			{
				id: '70',
				text: 'QA/QC'
			},
			{
				id: '3',
				text: 'Quảng cáo/Khuyến mãi/Đối ngoại'
			},
			{
				id: '68',
				text: 'Sản phẩm công nghiệp'
			},
			{
				id: '26',
				text: 'Sản Xuất'
			},
			{
				id: '157',
				text: 'Tài chính công nghệ'
			},
			{
				id: '59',
				text: 'Tài chính/Đầu tư'
			},
			{
				id: '63',
				text: 'Thời trang'
			},
			{
				id: '51',
				text: 'Thời vụ/Hợp đồng ngắn hạn'
			},
			{
				id: '49',
				text: 'Thu Mua/Vật Tư/Cung Vận'
			},
			{
				id: '54',
				text: 'Thực phẩm & Đồ uống'
			},
			{
				id: '156',
				text: 'Thương mại điện tử'
			},
			{
				id: '81',
				text: 'Trình dược viên'
			},
			{
				id: '48',
				text: 'Truyền hình/Truyền thông/Báo chí'
			},
			{
				id: '8',
				text: 'Tư vấn'
			},
			{
				id: '67',
				text: 'Tự động hóa/Ô tô'
			},
			{
				id: '36',
				text: 'Vận chuyển/Giao nhận'
			},
			{
				id: '41',
				text: 'Viễn Thông'
			},
			{
				id: '7',
				text: 'Xây dựng'
			},
			{
				id: '19',
				text: 'Xuất nhập khẩu'
			},
			{
				id: '79',
				text: 'Y sĩ/Hộ lý'
			},
			{
				id: '22',
				text: 'Y tế/Chăm sóc sức khỏe'
			},
			{
				id: '77',
				text: 'Địa chất/Khoáng sản'
			},
			{
				id: '76',
				text: 'Điện lạnh/Nhiệt lạnh'
			},
			{
				id: '64',
				text: 'Điện/Điện tử'
			}
		]
	} else {
		return [
			{
				id: '1',
				text: 'Accounting'
			},
			{
				id: '2',
				text: 'Administrative/Clerical'
			},
			{
				id: '3',
				text: 'Advertising/Promotion/PR'
			},
			{
				id: '4',
				text: 'Agriculture/Forestry'
			},
			{
				id: '37',
				text: 'Airlines/Tourism'
			},
			{
				id: '5',
				text: 'Architecture/Interior Design'
			},
			{
				id: '10',
				text: 'Arts/Design'
			},
			{
				id: '58',
				text: 'Auditing'
			},
			{
				id: '67',
				text: 'Auto/Automotive'
			},
			{
				id: '42',
				text: 'Banking'
			},
			{
				id: '43',
				text: 'Chemical/Biochemical'
			},
			{
				id: '7',
				text: 'Civil/Construction'
			},
			{
				id: '8',
				text: 'Consulting'
			},
			{
				id: '11',
				text: 'Customer Service'
			},
			{
				id: '78',
				text: 'Doctors'
			},
			{
				id: '79',
				text: 'Doctors/nurses'
			},
			{
				id: '12',
				text: 'Education/Training'
			},
			{
				id: '64',
				text: 'Electrical/Electronics'
			},
			{
				id: '156',
				text: 'E-commerce'
			},
			{
				id: '15',
				text: 'Entry level'
			},
			{
				id: '16',
				text: 'Environment/Waste Services'
			},
			{
				id: '17',
				text: 'Executive management'
			},
			{
				id: '18',
				text: 'Expatriate Jobs in Vietnam'
			},
			{
				id: '19',
				text: 'Export-Import'
			},
			{
				id: '63',
				text: 'Fashion/Lifestyle'
			},
			{
				id: '59',
				text: 'Finance/Investment'
			},
			{
				id: '157',
				text: 'Fintech'
			},
			{
				id: '74',
				text: 'FMCG'
			},
			{
				id: '54',
				text: 'Food & Beverage'
			},
			{
				id: '36',
				text: 'Freight/Logistics'
			},
			{
				id: '77',
				text: 'Geology/Mineral'
			},
			{
				id: '22',
				text: 'Health/Medical Care'
			},
			{
				id: '66',
				text: 'High Technology'
			},
			{
				id: '75',
				text: 'Household'
			},
			{
				id: '83',
				text: 'HSE'
			},
			{
				id: '23',
				text: 'Human Resources'
			},
			{
				id: '76',
				text: 'HVAC'
			},
			{
				id: '68',
				text: 'Industrial Products'
			},
			{
				id: '24',
				text: 'Insurance'
			},
			{
				id: '57',
				text: 'Internet/Online Media'
			},
			{
				id: '47',
				text: 'Interpreter/Translator'
			},
			{
				id: '55',
				text: 'IT - Hardware/Networking'
			},
			{
				id: '35',
				text: 'IT - Software'
			},
			{
				id: '25',
				text: 'Legal/Contracts'
			},
			{
				id: '62',
				text: 'Luxury Goods'
			},
			{
				id: '84',
				text: 'Maintenance'
			},
			{
				id: '82',
				text: 'Marine'
			},
			{
				id: '27',
				text: 'Marketing'
			},
			{
				id: '65',
				text: 'Mechanical'
			},
			{
				id: '49',
				text: 'Merchandising/Purchasing/Supply Chain'
			},
			{
				id: '21',
				text: 'NGO/Non-Profit'
			},
			{
				id: '28',
				text: 'Oil/Gas'
			},
			{
				id: '39',
				text: 'Other'
			},
			{
				id: '71',
				text: 'Overseas Jobs'
			},
			{
				id: '81',
				text: 'Pharmaceutical representatives'
			},
			{
				id: '6',
				text: 'Pharmaceutical/Biotech'
			},
			{
				id: '80',
				text: 'Pharmacist'
			},
			{
				id: '69',
				text: 'Planning/Projects'
			},
			{
				id: '72',
				text: 'Printing'
			},
			{
				id: '26',
				text: 'Production/Process'
			},
			{
				id: '70',
				text: 'QA/QC'
			},
			{
				id: '30',
				text: 'Real Estate'
			},
			{
				id: '73',
				text: 'Restaurant/Hotel'
			},
			{
				id: '32',
				text: 'Retail/Wholesale'
			},
			{
				id: '33',
				text: 'Sales'
			},
			{
				id: '34',
				text: 'Sales Technical'
			},
			{
				id: '56',
				text: 'Securities & Trading'
			},
			{
				id: '41',
				text: 'Telecommunications'
			},
			{
				id: '51',
				text: 'Temporary/Contract'
			},
			{
				id: '52',
				text: 'Textiles/Garments/Footwear'
			},
			{
				id: '48',
				text: 'TV/Media/Newspaper'
			},
			{
				id: '53',
				text: 'Warehouse'
			}
		]
	}
}
