import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { SlideShow } from '@microfrontends/react-components'
import SliderArrow from './icons/SliderArrow'

const CustomSlideShow = styled(SlideShow)`
	position: relative;
	.navigation-prev,
	.navigation-next {
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
	}
	&:hover {
		.navigation-prev,
		.navigation-next {
			opacity: 1;
		}
		&::before,
		&::after {
			opacity: 1;
		}
	}
	@media screen and (max-width: 768px) {
		&::before,
		&::after {
			display: none;
		}
	}
`

interface IProps {}

const Slides = (props: PropsWithChildren<IProps>) => {
	const { children } = props
	return (
		<>
			{Array.isArray(children) && (
				<CustomSlideShow
					id='hero-banner'
					autoEnable
					autoTime={7000}
					transitionTime={500}
					slides={{ spaceBetween: 0 }}
					pagination={{
						type: 'whiteArrowWithPagination',
						position: 'absolute',
						styleString: `
							bottom: 136px;
							@media screen and (max-width:768px) {
								position: absolute;
								bottom: -28px;
							}
						`,
						navigation: {
							prevEl: {
								Icon: <SliderArrow />
							},
							nextEl: {
								Icon: <SliderArrow type='right' />
							}
						}
					}}
				>
					{children}
				</CustomSlideShow>
			)}

			{!Array.isArray(children) && children}
		</>
	)
}

export default Slides
