import { Colors, colors } from '@microfrontends/react-components'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%
  }
`

export const NewBannerContainer = styled.div`
	padding: 0 0 40px 0;
	background: linear-gradient(270deg, #005aff 0%, #001744 73.72%);
	color: #fff;
	@media screen and (max-width: 768px) {
		padding: 0;
	}
	& > .sectionBlock {
		padding-top: 0;
	}
`

export const SearchSection = styled.div`
	margin: 16px 0;
`

export const SearchBarPlaceholder = styled.div`
	position: relative;
	height: 40px;
`

export const SearchBarWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	div[class*='SearchBar-module_mainTagLine__'] {
		display: none;
	}
	div[class*='SearchBar-module_searchBarWrappper'] {
		div[class*='SearchBar-module_searchBarMobile__'] {
			background-color: #f4f4f7;
		}
		> div {
			background-color: rgba(0, 0, 0, 0.15);
		}
		div[class*='SearchBar-module_isFocus__'] {
			background-color: #fff;
		}
	}
	div[class*='SearchBar-module_searchBar__'] {
		border-radius: 12px;
	}
	@media screen and (max-width: 768px) {
		z-index: 8;
		&:has(div[data-cname='SearchBackground']) {
			/* display: none; */
			z-index: 11 !important;
		}
	}
`

export const SlideWrapper = styled.div`
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.16);
	border-radius: 12px;
	@media screen and (max-width: 768px) {
		box-shadow: unset;
		margin-bottom: 16px;
	}
	.navigation-prev:hover,
	.navigation-next:hover {
		svg {
			path {
				fill: #fff !important;
			}
			rect {
				stroke: #fff !important;
			}
		}
	}
	> div {
		// Parent of slider
		> div:first-child {
			border-radius: 12px;
			overflow: hidden;
		}
		div[data-id*='hero-banner-pagination-'] {
			padding: 5px;
			> div {
				margin: 0;
				width: 10px;
				height: 10px;
			}
		}
		div[data-id*='hero-banner-pagination-true'] {
			> div {
				width: 24px;
				background-color: #fff;
				border-radius: 100px;
				border: 1px solid ${colors.neutral[10]};
			}
		}
		div[data-id*='hero-banner-pagination-false'] {
			> div {
				background-color: #fff;
				opacity: 0.7;
				border: 1px solid ${colors.neutral[10]};
			}
		}
		.navigation-prev,
		.navigation-next {
			top: 0;
			border-radius: 0;
			transform: initial;
			width: 100px;
			height: calc(100% - 120px);
			display: flex;
			align-items: center;
			/* justify-content: center; */
			@media screen and (max-width: 768px) {
				height: calc(100% - 145px);
				opacity: 1;
			}
		}
		.navigation-prev {
			left: 0;
			background: linear-gradient(
				270deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.35) 100%
			);
			border-radius: 12px 0 0 12px;
			> svg {
				margin-left: 20px;
			}
		}
		.navigation-next {
			right: 0;
			flex-direction: row-reverse;
			background: linear-gradient(
				90deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.35) 100%
			);
			border-radius: 0 12px 12px 0;
			> svg {
				margin-right: 20px;
			}
		}
	}
`

export const FadeInSection = styled.div`
	animation: ${fadeIn} ease-in-out 1s;
	border-radius: 12px;
	overflow: hidden;
	> div > div > div {
		height: 100% !important;
	}
`

export const SlideSection = styled.div`
	background: rgba(0, 0, 0, 0.3);
	border-radius: 12px;
`

export const BannerCompanySection = styled.a`
	padding: 24px;
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 16px;
	align-items: center;
	color: #fff;
	&:hover {
		color: #fff;
	}
	img {
		min-height: 72px !important;
		max-height: 72px !important;
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: unset;
		padding: 16px;
		min-height: 146px;
		> div {
			align-items: start;
		}
		img {
			min-height: 48px !important;
			max-height: 48px !important;
		}
	}
`

export const Company = {
	Container: styled.div`
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 16px;
		/* @media screen and (max-width: 768px) {
			min-height: 146px;
		} */
		> div {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
		}
		@media screen and (max-width: 768px) {
			column-gap: 8px;
		}
	`,
	Quote: styled.p`
		margin-bottom: 4px;
		font-size: 16px;
		line-height: 20px;
		font-weight: 600;
		@media screen and (max-width: 768px) {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			font-size: 14px;
			line-height: 18px;
			font-weight: 500;
			color: #fff;
			/* min-height: 36px; */
		}
	`,
	Name: styled.p`
		margin-bottom: 0;
		font-size: 16px;
		line-height: 20px;
		color: #aeaeae;
		@media screen and (max-width: 768px) {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			line-height: 18px;
			font-size: 14px;
			font-weight: 500;
			min-height: 18px;
		}
	`,
	Link: styled.span`
		border: 1px solid #fff;
		padding: 14px 16px;
		border-radius: 6px;
		transition: all 0.25s ease-in-out;
		color: #fff;
		font-weight: 600;
		&:hover {
			border-color: ${Colors.button};
		}
		@media screen and (max-width: 768px) {
			text-align: center;
			font-size: 14px;
			line-height: 18px;
			font-weight: 500;
			padding: 10px 16px;
		}
	`
}
