import {
	ELanguage,
	AspectRatio,
	Skeleton,
	SearchBarUnstable
} from '@microfrontends/react-components'
import React, { useEffect, useState } from 'react'

import SectionBlock from '../sectionBlock'
import { jobLocationList } from '../../services/constants'
import SlideShow from '../Banners/HeroBanner/Slides'

import {
	NewBannerContainer,
	SearchSection,
	SearchBarWrapper,
	SearchBarPlaceholder,
	SlideSection,
	SlideWrapper,
	FadeInSection,
	BannerCompanySection,
	Company
} from './styles'
import {
	EMode,
	EPages,
	initialDataProvider
} from '@microfrontends/app-shell-v2'
import { getHeroBanners } from '../Banners/apiClient'
import { TBanner, THeroBanner } from '../../services/type'
import useDelay from '../../hooks/useDelay'
import dynamic from 'next/dynamic'

const BannerItem = dynamic(() => import('./BannerItem'))

interface IProps {
	detectedLocation: {
		parentId: number
		childrenIds: number[]
	}[]
	serviceWorker: ServiceWorker | null
	language: ELanguage
	user: any
	userIP: string
	translate: (key: string) => string
	banners?: THeroBanner
	isMobile: boolean
	t: (key: string) => string
	mode: EMode
}

const NewBanner: React.FC<IProps> = ({
	language,
	user,
	userIP = '',
	banners,
	isMobile,
	mode,
	t
}) => {
	const [heroBanners, setHeroBanners] = useState<TBanner[] | undefined>(
		banners?.banners
	)
	const [isLoaded, setIsLoaded] = useState<boolean>(
		banners?.banners ? true : false
	)
	const delayTime = !isMobile ? 4000 : 6000

	const delay = useDelay(delayTime)

	const preMapLocationList = jobLocationList(language)
	const mappedLocationList = preMapLocationList.map((item) => {
		return {
			id: item.id,
			name: item.text
		}
	})

	useEffect(() => {
		if (heroBanners?.length === 0) {
			;(async () => {
				setIsLoaded(false)
				const bannerResponse = await getHeroBanners(language)
				setHeroBanners(bannerResponse.banners)
				setIsLoaded(true)
			})()
			return
		} else {
			setIsLoaded(true)
		}
	}, [heroBanners?.length, language])

	const renderSkeleton = () => {
		return (
			<SlideSection>
				<a href='' target='_blank'>
					<FadeInSection className='banner-fade-in-section'>
						<AspectRatio ratio={2560 / 900}>
							<Skeleton height={100} />
						</AspectRatio>
					</FadeInSection>
				</a>
				<BannerCompanySection href='' target='_blank'>
					<Company.Container>
						<Skeleton width={48} height={48} />
						<div>
							<Company.Quote>
								<Skeleton height={20} width={200} />
							</Company.Quote>
							<Company.Name>
								<Skeleton height={20} width={150} />
							</Company.Name>
						</div>
					</Company.Container>

					<Skeleton height={40} />
				</BannerCompanySection>
			</SlideSection>
		)
	}

	const renderSlide = () => {
		if (!isLoaded) {
			return renderSkeleton()
		}

		if (!heroBanners || heroBanners.length === 0) return <></>

		if (delay) {
			return <BannerItem banner={heroBanners[0]} t={t} priority />
		}

		let bannersDom: React.ReactNode[] = []

		for (let i = 0; i < heroBanners.length; i++) {
			if (i === 0) {
				continue
			}
			bannersDom.push(
				<BannerItem
					key={heroBanners[i]?.id}
					banner={heroBanners[i]}
					t={t}
				/>
			)
		}
		bannersDom.push(
			<BannerItem
				key={heroBanners[0]?.id}
				banner={heroBanners[0]}
				t={t}
			/>
		)

		return (
			<SlideShow>
				{bannersDom?.map((banner) => {
					return banner
				})}
			</SlideShow>
		)
	}

	return (
		<NewBannerContainer>
			<SectionBlock>
				<SearchSection>
					<SearchBarPlaceholder>
						<SearchBarWrapper>
							<SearchBarUnstable
								userId={user?.userId || 0}
								userJobFunction={user?.jobFunction || []}
								langCode={language}
								isMobile={isMobile}
								pageName={EPages.SEARCH_RESULT}
								dataProvider={initialDataProvider.exportProvider()}
								msBaseUrl={process.env.API_DOMAIN || ''}
								searchBarOptions={{ isHomepage: true }}
								userIP={userIP}
								VNWAppId={process.env.VNW_APP_ID || ''}
								responsiveStyle='fill'
								optionList={mappedLocationList}
								mode={mode}
							/>
						</SearchBarWrapper>
					</SearchBarPlaceholder>
				</SearchSection>
				<SlideWrapper data-name='slide-wrapper'>
					{renderSlide()}
				</SlideWrapper>
			</SectionBlock>
		</NewBannerContainer>
	)
}

export default React.memo(NewBanner)
