import { useEffect, useState } from 'react'

async function delay(ms: number) {
	return ms
		? new Promise((resolve) => setTimeout(resolve, ms))
		: Promise.resolve()
}

export default function useDelay(
	ms: number,

	defaultSetting: boolean | undefined = true
) {
	const [isDelay, setIsDelay] = useState(defaultSetting)

	useEffect(() => {
		if (ms) {
			delay(ms).then(() => setIsDelay(!defaultSetting))
		}
	}, [ms, defaultSetting])

	return isDelay
}
