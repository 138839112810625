import React from 'react'
const SliderArrow = ({ type = 'left' }: { type?: 'left' | 'right' }) => (
	<svg
		width={34}
		height={34}
		viewBox='0 0 34 34'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{
			transform: type === 'right' ? 'rotate(180deg)' : ''
		}}
	>
		<path
			d='M12.863 13.667a.533.533 0 0 0-.366.161l-2.62 2.62a.533.533 0 0 0 0 .85l2.62 2.618a.532.532 0 0 0 .874-.581.537.537 0 0 0-.12-.173l-1.756-1.757h12.579a.535.535 0 1 0 0-1.066h-12.58l1.757-1.756a.534.534 0 0 0-.388-.916Z'
			fill='#fff'
		/>
		<rect
			x={1}
			y={1}
			width={32}
			height={32}
			rx={16}
			stroke='#fff'
			strokeOpacity={0.5}
		/>
	</svg>
)
export default SliderArrow
